import React from 'react';
import Layout from '../../components/layouts/layout.component';
import CarDetailContainer from '../car-detail/CarDetailContainer.container';
import { goToCompare } from '../../api/navigation';
import queryString from 'query-string';


const PAGE_TITLE = 'Detalle de Vehículo - Carmuv'; // TODO: validar
const META_DESCRIPTION = ''; //TODO: validar
const DETAIL_BREADCRUMB_INDEX = 1;

const getPathname = ({ brand, model }) => {
  if(model && brand) {
    return `/catalogo/?search=${brand.toUpperCase()} ${model.toUpperCase()}`;
  }
  if(brand) {
    return `/catalogo/?search=${brand.toUpperCase()}`;
  }
};

const CatalogDetailPageContainer = (props) => {
  const { location, brand, model, version } = props;
  const pageContext = {
    ...props.pageContext,
  };
  const query = queryString.parse(location.search);
  pageContext.breadcrumb.crumbs.splice(2);
  pageContext.breadcrumb.crumbs[DETAIL_BREADCRUMB_INDEX] = { pathname: '/catalogo/', crumbLabel: 'Autos' };

  pageContext.breadcrumb.crumbs.push({
    crumbLabel: brand,
    pathname: getPathname( { brand }),
  });
  pageContext.breadcrumb.crumbs.push({
    crumbLabel: model,
    pathname: getPathname( { brand, model }),
  });
  pageContext.breadcrumb.crumbs.push({
    crumbLabel: version,
    pathname: location.href
  });

  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader
      description={META_DESCRIPTION}
    >
      <CarDetailContainer carId={query.id} goToCompare={goToCompare}/>
    </Layout>
  );
};

export default CatalogDetailPageContainer;

