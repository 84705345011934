import React from 'react';

import { Router } from '@reach/router';
import CatalogDetailPageContainer from '../containers/listaCatalogo/CatalogDetailPage.container';

const CatalogDetailRouter = (props) => {
  return (
    <Router basepath="/detalle" >
      <CatalogDetailPageContainer {...props} path="/:brand/:model/:version"  />
    </Router>
  );
};

export default CatalogDetailRouter;
