import React from 'react';
import { Box, makeStyles, } from '@material-ui/core';
import MaskedTextField from '../common/MaskedTextField.component';
import Text from '../common/text.component';
import { TELEPHONE_MASK, NAME_MASK } from '../../utils/textMask.utils';

const LABEL_NAME = 'Nombre del cliente (Obligatorio)';
const LABEL_LASTNAME = 'Apellido del cliente (Obligatorio)';
const LABEL_TELEPHONE = 'Teléfono del cliente (Obligatorio)';
const TEXT_TITLE_NO_LOGIN= <Text>Completá el formulario con tus datos y nos <br/> comunicaremos con vos.</Text>;
const TEXT_TITLE=<Text>Completá el formulario y nos <br/> comunicaremos con vos.</Text>
const LABEL_EMAIL = 'Email (Obligatorio)';
const LABEL_NAME_NO_LOGIN = 'Nombre (Obligatorio)';
const LABEL_LASTNAME_NO_LOGIN = 'Apellido (Obligatorio)';
const LABEL_TELEPHONE_NO_LOGIN = 'Teléfono (Obligatorio)';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
  },
  textInput: {
    width:'100%',
    marginTop: theme.spacing(3.8),
    '& .MuiOutlinedInput-root': {
      '& fieldset':{
        height: '3.125rem',
        border: '1px solid' + theme.palette.grayScale.g300
      },
      '& input':{
        marginTop: '4px'
      }
    },
    '& label': {
      top: '4px',
      color: theme.palette.grayScale.g300
    },
    '& .MuiInputLabel-shrink':{
      transform: 'translate(0.875rem, -1.3rem) scale(0.75)',
      color: theme.palette.grayScale.g300
    }
  },
  firstTextInput: {
    width:'100%',
    marginTop: theme.spacing(4),
    '& .MuiOutlinedInput-root': {
      '& fieldset':{
        height: '3.125rem'
      },
      '& input':{
        marginTop: '4px'
      }
    },
    '& label': {
      top: '4px'
    },
    '& .MuiInputLabel-shrink':{
      transform: 'translate(0.875rem, -1.3rem) scale(0.75)'
    }
  },
  title: {
    display: 'flex',
    justifyContent:'center',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightRegular,
    '& span': {
      fontSize: theme.typography.size.small,
      color: '#000',
    }
  },
}));

const ReservaForm = ({
  submited,
  fields,
  onSubmit,
  onFieldChanges,
  isLoggedIn }) => {
  const classes = useStyles();

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields };
    newFields[key] = value;
    onFieldChanges(newFields);
  };

  // poner un evento que cuando sea key value = 13 (enter) en telefono solamente

  return(
    <form className={classes.container}>
      <Box className={classes.title}>
        {isLoggedIn ? TEXT_TITLE : TEXT_TITLE_NO_LOGIN}
      </Box>
      <MaskedTextField
        data-test-id="input-name"
        tabIndex="0"
        className={classes.textInput}
        label={isLoggedIn ? LABEL_NAME : LABEL_NAME_NO_LOGIN}
        value={fields.name}
        mask={NAME_MASK}
        onChange={(evt) => handleTextFieldChange( 'name', evt.target.value)}
        variant="outlined"
      />
      <MaskedTextField
        data-test-id="input-lastname"
        tabIndex="1"
        className={classes.textInput}
        label={isLoggedIn ? LABEL_LASTNAME : LABEL_LASTNAME_NO_LOGIN}
        value={fields.lastname}
        mask={NAME_MASK}
        onChange={(evt) => handleTextFieldChange( 'lastname', evt.target.value)}
        variant="outlined"
      />
      { !isLoggedIn &&
        <MaskedTextField
          data-test-id="input-email"
          tabIndex="3"
          className={classes.textInput}
          label={LABEL_EMAIL}
          value={fields.email}
          onChange={(evt) => handleTextFieldChange( 'email', evt.target.value)}
          variant="outlined"
          type="email"
          mask={false}
        />
      }
      <MaskedTextField
        data-test-id="input-telephone"
        tabIndex="2"
        className={classes.textInput}
        label={isLoggedIn ? LABEL_TELEPHONE : LABEL_TELEPHONE_NO_LOGIN}
        value={fields.telephone}
        mask={TELEPHONE_MASK}
        placeholder={'Ej: 3511234567'}
        onChange={(evt) => handleTextFieldChange( 'telephone', evt.target.value)}
        variant="outlined"
      />
    </form>
  );
};


export default ReservaForm;
