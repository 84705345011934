import React, { useState } from 'react'
import {
  makeStyles,
  Box,
  useMediaQuery,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import Text from '../common/text.component'
import ReservaForm from './ReservaForm.component'
import ModalFormButton from '../common/ModalFormButton.component'
import { theme as globalTheme } from '../testUtils'
import { LoadingMessage } from '../common/LoadingModal.component'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '../common/Dialog.component'
import Button from '../common/button.component'
import FlashMessage from '../common/FlashMessage.component'
import { CarMedia } from '../car-card/components/'
import Chip from '../common/Chip.component'
import ColorBox from '../common/ColorBox.component'
import { formatPrice, formaterNumber } from '../../utils/currency.utils'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { navigate } from 'gatsby';

const MODAL_TITLE = '¡Excelente elección!';
const MODAL_SUBTITLE = 'Vas a solicitar la reserva de este modelo:'
const CONFIRM_BTN_TEXT = 'Enviar datos';
const CONTINUE_BTN_TEXT = 'Continuar';
const GO_TO_BLOG_BTN_TEXT = 'Ir al blog';
const SUBMIT_ERROR_MESSAGE =
  '¡Lo sentimos! No se pudo realizar la reserva, intentá nuevamente.'
const ERROR_TITLE = 'La reserva tuvo un error.'
const SUCCESS_TITLE = 'Listo! Tu solicitud está en camino';
// eslint-disable-next-line max-len
const SUCCES_MESSAGE = <div>Dentro de las próximas horas nos estaremos comunicando con vos para ayudarte.<br /><br />Mientras, podés conocer las últimas novedades en el blog.</div>;
const LOADING_MESSAGE_BOOKING = '¡Ya casi, no te vayas!';
const LOADING_LEYEND_BOOKING = 'Estamos preparando tu solicitud.';
const FORM_INITIAL_FIELDS = {
  name: '',
  lastname: '',
  telephone: '',
  email: '',
}

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '640px',
    },
  },
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      padding: '1.7rem 2.5rem 1.7rem 2.5rem',
      minHeight: '38rem',
      width: '40rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1.7rem 2.5rem 1.7rem 2.5rem',
      minHeight: '38rem',
      width: '100vw'
    },
    borderBottom: '0px solid rgba(0, 0, 0, 0)'
  },
  title: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1.625rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.grayScale.g500,
    fontWeight: theme.typography.fontWeightBold
  },
  subTitle: {
    display: 'block',
    textAlign: 'left',
    fontSize: '1.125rem',
    color: theme.palette.grayScale.g500,
    fontWeight: theme.typography.fontWeightRegular
  },
  button: {
    width: '100%',
    minHeight: '3.125rem',
    borderRadius: theme.borderRadius.large,
    justifySelf: 'flex-end',
  },
  dialogButton: {
    width: '100%',
    marginRight:'2rem',
    marginLeft:'2rem'
  },
  buttonContainer: {
    paddingTop: theme.spacing(4.4),
  },
  header: {
    width: '100%',
    margin: 'auto',
    display: 'inline-flex',
    border: '1px solid #D1D1D1',
    borderRadius: '8px',
    padding: '5px',
  },
  informationCard: {
    width: '60%',
    padding: '5px 0px 0px 15px',
  },
  informationCardMobile: {
    width: '60%',
    padding: '5px 0px 0px 15px',
  },
  priceAndColor: {
    display: 'block',
  },
  closeButton: {
    fontSize: '1.25rem',
    position: 'absolute',
    top: '1.9rem',
    right: '1.5rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  media: {
    height: 'auto',
    width: '40%',
  },
  mediaMobile: {
    height: 'auto',
    width: '40%',
  },
  brand: {
    fontSize: '1.3rem',
    letterSpacing: '-0.16px',
    padding: '1rem 0',
    lineHeight: 1.3,
    textTransform: 'capitalize',
    width: '50%',
  },
  brandMobile: {
    fontSize: '1rem',
    letterSpacing: '-0.16px',
    padding: '1rem 0',
    lineHeight: 1.3,
    textTransform: 'capitalize',
    width: '50%',
  },
  model: {
    fontSize: '.9rem',
    letterSpacing: '-0.1px',
    lineHeight: 1,
    whiteSpace: 'pre-wrap',
    marginRight: '0.5rem',
    fontWeight: 400,
    color: theme.palette.grayScale.g500,
  },
  chip: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.grayScale.g300}`,
    margin: '0.2rem',
    flexDirection: 'column',
    width: 'fit-content',
    minWidth: '30px',
    padding: '2px',
    lineHeight: '14px',
    textTransform: 'capitalize',
    fontSize: '.7rem',
  },
  pills: {
    padding: '2px 0',
    color: `${theme.palette.grayScale.g300}`,
    fontSize: '1rem',
  },
  pillsMobile: {
    padding: '2px',
    color: `${theme.palette.grayScale.g300}`,
    fontSize: '.8rem',
  },
  price: {
    fontSize: '1.3rem',
    fontWeight: 700,
  },
  priceMobile: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  leyend: {
    fontSize: '.8rem',
    display: 'block',
    marginTop: theme.spacing(1),
  },
  colorBox: {
    marginTop: theme.spacing(1.3),
    textTransform: 'capitalize',
  },
  fontColorBox: {
    fontSize: '.8rem',
  },
  crossedOut: {
    color: theme.palette.grayScale.g400,
    fontSize: '.8rem',
    textDecoration: 'line-through',
  },
  company: {
    marginTop: theme.spacing(1.3),
  },
  locationBox: {
    marginTop: theme.spacing(1.3),
    fontSize: '.8rem',
  },
  observationPill:{
    borderRadius:'5px',
    backgroundColor: '#E8E8E8',
    width: 'fit-content',
    height: '1.5rem',
    padding: '0 0.5rem',
    lineHeight: '1.5rem',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    color: '#7A659B'
  },
  modelAndObservationBox:{
    [theme.breakpoints.down('sm')]:{
      display:'flex',
      flexDirection:'column'
    }
  }
}))

const ReservaDialog = ({
  open,
  car,
  submitError,
  onBack,
  loading,
  success,
  onConfirm,
  onContinue,
  price,
  observation,
  colorHex,
  colorName,
  isLoggedIn,
}) => {
  const classes = useStyles()
  const fullScreen = useMediaQuery(globalTheme.breakpoints.down('sm'))
  const [fields, setFields] = useState(FORM_INITIAL_FIELDS)
  const [formSubmited, setFormSubmited] = useState(false)
  const [formHasError, setFormHasError] = useState(false)
  const externalUrl = process.env.GATSBY_BLOG_URL;

  const handleOnFieldChanges = newFields => {
    setFields(newFields)
    submitError = false
  }
  const isFormIncomplete = () =>
    fields === null || !fields.name || !fields.lastname || !fields.telephone || !validateLengthTelephone(fields.telephone);

  const isFormIncompleteNoLogin = () =>
  fields === null || !fields.name || !fields.lastname || !fields.telephone || !fields.email || !validateEmail(fields.email) || !validateLengthTelephone(fields.telephone);

  const handleOnSubmit = () => {
    setFormSubmited(true)
    !formHasError && onConfirm(fields)
  }

  const handleOnError = hasError => {
    setFormHasError(hasError)
  }

  const handleClose = () => {
    clearDialog();
    if(!isLoggedIn && success) {
      navigate('/catalogo/');
    }else {
      onBack();
    }
  };

  const clearDialog = () => {
    setFields({ name: '', lastname: '', telephone: '', email: '' });
  };

  const validateEmail = (elementValue) => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue);
  };

  const validateLengthTelephone = (elementValue) => {
    return elementValue.length >= 10
  };

  function formatObservation(obs) {
    let formattedObservation = obs.toLowerCase();
    formattedObservation = formattedObservation[0].toUpperCase().concat(formattedObservation.substring(1,obs.length));
    return formattedObservation;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} classDialog={classes.dialog} >
      <DialogContent className={classes.dialogContent} dividers>
        {!fullScreen ? (
          <CloseIcon className={classes.closeButton} onClick={handleClose} />
        ) : null}
        {loading ? (
          <OnLoadingContent loadingMessage={LOADING_MESSAGE_BOOKING} loadingLeyend={LOADING_LEYEND_BOOKING} updating={true} />
        ) : success || submitError ? (
          <FlashMessage
            onContinue={onBack}
            errorTitle={ERROR_TITLE}
            succesTitle={SUCCESS_TITLE}
            successMessage={SUCCES_MESSAGE }
            fullScreen={fullScreen}
            success={success}
            continueButtonText={success ? GO_TO_BLOG_BTN_TEXT : CONTINUE_BTN_TEXT}
            external={success}
            externalUrl={externalUrl}
          />
        ) : (
          <Box>
            <Text bold className={classes.title}>
              {MODAL_TITLE}
            </Text>
              <Text className={classes.subTitle}>
                {MODAL_SUBTITLE}
              </Text>
            <Box className={classes.header}>
              <Box
                className={!fullScreen ? classes.media : classes.mediaMobile}
              >
                <CarMedia photos={(car.images.length > 0 ? [car.images[0]] : [])} onDrag={isDragging => {}} />
              </Box>
              <Box
                className={
                  !fullScreen
                    ? classes.informationCard
                    : classes.informationCardMobile
                }
              >
                <Box id="info-bran-model" display="flex" flexDirection="column">
                  <Box>
                    <Text
                      bold
                      className={
                        !fullScreen ? classes.brand : classes.brandMobile
                      }
                    >
                      {`${car.brand.name || ''} ${car.model.name || ''}`}
                    </Text>
                  </Box>
                  <Box className={classes.modelAndObservationBox}>
                    <Text className={classes.model}>
                      {car.version.name || ''}
                    </Text>
                    {observation ? <Text className={classes.observationPill}>{formatObservation(observation)}</Text> : null }
                  </Box>
                </Box>
                <Box className={ !fullScreen ? classes.pills : classes.pillsMobile}>
                  {!fullScreen ? (
                    <>
                      {!car.is0km && car.year && (
                        <Chip className={classes.chip} label={car.year} />
                      )}
                      <Chip
                        className={classes.chip}
                        label={
                          car.is0km ? '0 Km' : `${formaterNumber(car.km)} Km`
                        }
                      />
                      {car.fuel && (
                        <Chip
                          className={classes.chip}
                          label={car.fuel.toLowerCase()}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {!car.is0km && car.year ? `${car.year} - ` : null}
                      {car.is0km
                        ? ' 0 Km - '
                        : ` ${formaterNumber(car.km)} Km - `}
                      {car.fuel && car.fuel.toLowerCase()}
                    </>
                  )}
                </Box>
                <Box className={classes.priceAndColor}>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                    <Box style={{ paddingRight: '10px' }}>
                      <Text bold className={!fullScreen ? classes.price : classes.priceMobile}>
                        {formatPrice(isLoggedIn ?
                          ( car.is0km ? price : car.resalePrice) : car.netSalePrice, car.currency_symbol)}
                      </Text>
                    </Box>
                    { isLoggedIn &&
                      <Box >
                        <Text bold className={classes.crossedOut}>
                          {formatPrice(car.factoryPrice, car.currency_symbol)}
                        </Text>
                      </Box>
                    }
                  </Box>
                </Box>
                <Box
                  className={classes.colorBox}
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                >
                  <ColorBox
                    hex={car.is0km ? colorHex : car.stock[0].color.color}
                    color={car.is0km ? colorName : car.stock[0].color.name}
                    isCatalogCarmuv={true}
                    reserva={'true'}
                  />
                </Box>
                {isLoggedIn && !car.is0km ? (
                  <Box
                    display="flex"
                    className={classes.locationBox}
                    flexDirection="row"
                    alignItems="center"
                  >
                    <LocationOnIcon />
                    <Text
                      medium
                      style={
                        !fullScreen
                          ? { fontSize: '1rem' }
                          : { fontSize: '.8rem' }
                      }
                    >
                      {car.branchOfficeInfo.name}
                    </Text>
                  </Box>
                ) : null}

                { isLoggedIn &&
                  <Box
                    className={classes.company}
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                  >
                    {car.company && (
                      <Text light className={classes.leyend}>
                        {`Comercializado por ${car.company.name} ${car.carLot.name}`}
                      </Text>
                    )}
                  </Box>
                }
              </Box>
            </Box>
            <ReservaForm
              submited={formSubmited}
              fields={fields}
              onSubmit={handleOnSubmit}
              onError={handleOnError}
              onFieldChanges={handleOnFieldChanges}
              isLoggedIn={isLoggedIn}
            />
            {!fullScreen && (
              <Box className={classes.buttonContainer}>
                <ModalFormButton
                  primary
                  data-test-id="confirmar-reserva-btn"
                  submitError={submitError}
                  submitErrorMessage={SUBMIT_ERROR_MESSAGE}
                  text={CONFIRM_BTN_TEXT}
                  onConfirm={handleOnSubmit}
                  disable={isLoggedIn ? isFormIncomplete() : isFormIncompleteNoLogin()}
                  className={classes.button}
                />
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      {fullScreen && !loading && (
        <DialogActions>
          {!success ? (
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                primary
                className={classes.dialogButton}
                data-test-id="confirmar-reserva-dialogBtn"
                disabled={isFormIncomplete()}
                onClick={handleOnSubmit}
              >
                {CONFIRM_BTN_TEXT}
              </Button>
            </Box>
          ) : (
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                primary
                className={classes.dialogButton}
                data-test-id="continuar-navegando-dialogBtn"
                onClick={() => onContinue()}
              >
                {CONTINUE_BTN_TEXT}
              </Button>
            </Box>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

const OnLoadingContent = ({ loadingMessage, loadingLeyend, updating }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <LoadingMessage message={loadingMessage} leyend={loadingLeyend} updating={updating} />
    </Box>
  );
};

export default ReservaDialog;
