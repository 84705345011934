import React from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '../common/TextField.component';

const LABEL_SUGGESTION = 'Escriba su sugerencia aquí';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: '100%',
  },
  textInput: {
    width:'100%'
  },
}));

const SuggestionForm = ({
  submited,
  fields,
  onSubmit,
  onFieldChanges }) => {
  const classes = useStyles();

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields };
    newFields[key] = value;
    onFieldChanges(newFields);
  };

  return(
    <form className={classes.container}>
      <TextField
        data-test-id="input-lastname"
        tabIndex="1"
        multiline
        rows={6}
        inputProps={{
          maxLength: 255,
        }}
        className={classes.textInput}
        label={LABEL_SUGGESTION}
        value={fields.suggestion}
        onChange={(evt) => handleTextFieldChange( 'suggestion', evt.target.value)}
        variant="outlined"
      />
    </form>
  );
};


export default SuggestionForm;
