import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Text from './text.component';
import clsx from 'clsx';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    boxShadow: 'none',
  },
  borderOnTop: {
    borderTop: `1px solid ${theme.palette.grayScale.g400}`
  },
  expanded: {
    minHeight: '38px !important'
  },
  heading: {
    fontSize: theme.typography.size.small,
    fontWeight: theme.typography.fontWeightRegular,
  },
  headingCatalogSection: {
    fontWeight: 'bold',
  },
  headingContainerCatalogSection: {
    backgroundColor: '#F6F6F6',
    maxHeight: '48px',
    margin: '4px 0'
  }
}));

export default function ExpandablePanel({
  heading,
  children,
  borderOnTop = true,
  expanded,
  defaultExpanded,
  isCatalogSection = false,
  expansionPanelDetailsClass = ''
}) {
  const classes = useStyles();
  const [expand, setExpand] = useState(expanded);

  const handleChange = () =>{
    setExpand(!expand);
  };

  useEffect(() => {
    setExpand(expanded);
  }, [expanded]);

  return (
    <div className={classes.root}>
      <ExpansionPanel
        defaultExpanded={defaultExpanded}
        square
        onChange={handleChange}
        className={clsx(classes.paper,{ [classes.borderOnTop]: borderOnTop })}
        classes={{ expanded: classes.expanded }}>
        <ExpansionPanelSummary
          className={clsx({[classes.headingContainerCatalogSection]: isCatalogSection})}
          expandIcon={<ExpandMoreIcon />}
        >
          <Text className={clsx(classes.heading,{ [classes.headingCatalogSection]: isCatalogSection})}>{heading}</Text>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={expansionPanelDetailsClass}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
