import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { InformativeTabs, InformationCard, ComparadorCard, CarPhotoGalery } from '../../components/CarDetail';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GET_CAR, GET_BRAND_LOGO, GET_USER_AUTH } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import ReservaDialogContainer from '../reservation/ReservaDialog.container';
import Licenciado from '../../components/CarDetail/licenciado/Licenciado.component';
import LicenciadoUsado from '../../components/CarDetail/licenciadoUsado/LicenciadoUsado.component';
import SuggestDialogContainer from '../suggestion/SuggestionDialog.container';
import LoadingModal from '../../components/common/LoadingModal.component';
import { goToPresupuesto } from '../../api/navigation';
import { checkIfWasLoggedIn } from '../../utils/hooks/useIsLoggedUser';
import { STOCK_STATES } from '../../utils/constants';
import { isResellerUser } from '../../api/authorization';

const INITIAL_RESERVATION_DATA =
{
  price: null,
  color: null,
  stockId: null,
  currencySimbol: null
};

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '2rem 4rem 4rem 4rem',
    }
  },
  desktopDetails: {
    width: '100%',
    '& $galleryTabs': {
      width: '70%',
      flex: '1 0 70%',
      maxWidth: '70%',
    },
    '& $infoCtaComp': {
      width: '30%',
      flex: '1 0 30%',
      maxWidth: '30%',
    },
  },
  mobileDetails: {
    width: '100%',
    padding: '1.27rem',
    paddingTop: 0,
  },
  galleryTabs: {
    display: 'flex',
    flexDirection: 'column',
  },
  leyend: {
    fontSize: theme.typography.size.smaller,
    fontStyle: 'italic',
    position: 'relative',
    top: '-10px',
  },
  infoCtaComp: {
    display: 'flex',
    flexDirection: 'column',
  },
  licenciado: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    }
  },
  tabs: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  galleryMobileContainer: {
    width: '100%',
    marginBottom: theme.spacing(6.5),
    marginTop: theme.spacing(1.25),
    '& .slick-slider :is(.MuiGrid-root, .MuiCardMedia-root)': {
      '@media (max-width: 412px)': {
        minHeight:'55vw'
      }
    },
    '& .slick-arrow svg': {
      height: theme.spacing(2),
      width: theme.spacing(2)
    },
    '& .slick-dots': {
      bottom: 'initial',
      top: '125%',
      [theme.breakpoints.up('sm')]: {
        top: '117.5%',
      },
      '@media (max-width: 411px)': {
        top:'calc(70vw + 1rem)'
      },
      '& li': {
        width: 'fit-content',
        height: 'fit-content',
        '& > div': {
          borderColor: theme.palette.grayScale.g300,
          height: theme.spacing(2),
          width: theme.spacing(2),
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
        }
      }
    }
  }
}));

const CarDetailContainer = ({ carId, goToCompare }) => {

  const matches = useMediaQuery('(min-width: 1025px)');
  const [logo, setLogo] = useState('');
  const [reservationData, setReservationData] = useState(INITIAL_RESERVATION_DATA);
  const [openReservaModal, setOpenReservaModal] = useState(false);
  const [openSuggestionDialog, setOpenSuggestionDialog] = useState(false);
  const [car, setCar] = useState(null);
  const classes = useStyles();

  const sortStocksByMinPrice = (stocks) => stocks.sort((stockA, stockB) => stockA.price - stockB.price)

  const { data: authData } = useQuery(GET_USER_AUTH, { fetchPolicy: 'network-only' ,onCompleted: (authData)=>checkIfWasLoggedIn((authData && authData.currentUser !== null))});
  const { loading: loadingCar } = useQuery(GET_CAR, {
    variables: { id: carId },
    onCompleted: data => {
      const car = data.car;
      car.stocks = sortStocksByMinPrice(car.stock);
      if (car.stocks && car.stocks.length) {
        setSelectedStock(car.stocks[0].id);
      }
      setCar(car);
    }
  });

  const brandName = car?.brand?.name;
  const isReseller = isResellerUser(authData?.currentUser?.profile?.role_name);
  const stockList = car?.stock || [];
  const [selectedStock, setSelectedStock] = useState();

  useQuery(GET_BRAND_LOGO, {
    skip: !brandName,
    variables: { brand: brandName },
    onCompleted: data => setLogo(data?.brandLogo),
  });

  function handleOnCompare() {
    goToCompare(car);
  }


  const handleReservaBack = () => {
    setOpenReservaModal(false);
  };

  const findByStockId = () => {
    return stockList.find(stock => stock.id === selectedStock)
  };

  const handleSuggest = () => {
    setOpenSuggestionDialog(true);
  };

  const handleSuggestBack = () => {
    setOpenSuggestionDialog(false);
  };

  function handleOnBudget() {
    const foundStockGroup = car.stock.find(stock => stock.id === selectedStock);
    goToPresupuesto({ ...car, selectedStock: foundStockGroup.activeStockId });
  }



  return (
    !loadingCar && car !== null ?
      <Box display="flex" flexDirection="row" className={classes.root} id="detalle-container">
        {
          matches ? (
            <Box display="flex" className={classes.desktopDetails} id="desktop-detail">
              <Box className={classes.galleryTabs} id="gallery-tabs">
                <Box className={classes.gallery} marginBottom="4rem">
                  <CarPhotoGalery photos={car.images} />
                </Box>

                <Box className={classes.tabs} id="tabs">
                  <InformativeTabs car={car} isLoggedIn={isReseller} onSuggest={handleSuggest} />
                </Box>
              </Box>
              <Box className={classes.infoCtaComp} id="info-ctaComp">
                <Box className={classes.info}>
                  <InformationCard
                    versionName={car.version.name}
                    brandName={car.brand.name}
                    modelName={car.model.name}
                    branchOffice={car.branchOfficeInfo}
                    factoryPrice={car.factoryPrice}
                    stock={findByStockId()}
                    is0km={car.is0km}
                    year={car.year}
                    mileage={car.km}
                    fuel={car.fuel}
                    isLoggedIn={isReseller}
                    logo={logo}
                    isCatalogCarmuv={true}
                    currency_symbol={car.currency_symbol}
                    netSalePrice={car.netSalePrice}
                    resalePrice={car.resalePrice}
                  />
                </Box>
                {!loadingCar &&
                  (car.is0km ?
                    <Box className={classes.licenciado}>
                      <Licenciado
                        company={car.company}
                        carLot={car.carLot}
                        stockList={stockList}
                        selectedStock={selectedStock}
                        setSelectedStock={setSelectedStock}
                        onReservationClick={handleOnBudget}
                        currency_symbol={car.currency_symbol}
                        isLoggedIn={isReseller}
                      />
                    </Box>
                    :
                    <Box className={classes.licenciado}>
                      <LicenciadoUsado
                        company={car.company}
                        branchOffice={car.branchOfficeInfo}
                        carLot={car.carLot}
                        stockList={stockList}
                        isLoggedIn={isReseller}
                        onReservationClick={handleOnBudget}
                        isReserved={car.stock?.every(s => s.estado === STOCK_STATES.RESERVED)}
                      />
                    </Box>)
                }
                <Box className={classes.ctaComparador}>
                  <ComparadorCard goToCompare={handleOnCompare} borderOnTop={!isReseller} />
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box className={classes.galleryMobileContainer}>
                <CarPhotoGalery photos={car.images} />
              </Box>
              <Box display="flex" flexDirection="column" className={classes.mobileDetails} id="mobile-detail">
                <Box className={classes.info}>
                  <InformationCard
                    versionName={car.version.name}
                    brandName={car.brand.name}
                    modelName={car.model.name}
                    branchOffice={car.branchOfficeInfo}
                    factoryPrice={car.factoryPrice}
                    stock={findByStockId()}
                    is0km={car.is0km}
                    year={car.year}
                    mileage={car.km}
                    fuel={car.fuel}
                    isLoggedIn={isReseller}
                    logo={logo}
                    isCatalogCarmuv={true}
                    netSalePrice={car.netSalePrice}
                    currency_symbol={car.currency_symbol}
                    resalePrice={car.resalePrice}
                  />
                </Box>
                {!loadingCar &&
                  (car.is0km ?
                    <Box className={classes.licenciado}>
                      <Licenciado
                        company={car.company}
                        carLot={car.carLot}
                        isCatalogCarmuv={true}
                        stockList={stockList}
                        selectedStock={selectedStock}
                        setSelectedStock={setSelectedStock}
                        currency_symbol={car.currency_symbol}
                        isLoggedIn={isReseller}
                        onReservationClick={handleOnBudget}
                      />
                    </Box>
                    :
                    <Box className={classes.licenciado}>
                      <LicenciadoUsado
                        company={car.company}
                        branchOffice={car.branchOfficeInfo}
                        carLot={car.carLot}
                        stockList={stockList}
                        selectedStock={selectedStock}
                        onReservationClick={handleOnBudget}
                        isReserved={car.stock?.some(s => s.estado === STOCK_STATES.RESERVED)}
                      />
                    </Box>)
                }
                <Box className={classes.ctaComparador}>
                  <ComparadorCard goToCompare={handleOnCompare} borderOnTop={!isReseller} />
                </Box>
                <Box className={classes.tabs} marginBottom="2rem" id="tabs">
                  <InformativeTabs car={car} isLoggedIn={isReseller} loadingCar={loadingCar} onSuggest={handleSuggest} />
                </Box>
              </Box>
            </>
          )
        }
        {openReservaModal && <ReservaDialogContainer
          open={openReservaModal}
          car={car}
          logo={logo}
          stockId={reservationData.stockId}
          price={reservationData.price}
          selectedStock={findByStockId()}
          color={reservationData.color}
          applicant={authData?.currentUser}
          onBack={handleReservaBack}
          isLoggedIn={isReseller} />}
        {openSuggestionDialog && <SuggestDialogContainer
          open={openSuggestionDialog}
          car={car}
          logo={logo}
          applicant={authData?.currentUser}
          onBack={handleSuggestBack} />}
      </Box> : <LoadingModal />
  );
};

CarDetailContainer.propTypes = {
  carId: PropTypes.string.isRequired,
  goToCompare: PropTypes.func.isRequired,
};


export default CarDetailContainer;
