import React, { useEffect, useState } from 'react';
import StockDetail from './StockDetail.component';
import { Box, makeStyles } from '@material-ui/core';
import Text from '../../common/text.component';
import { sortByAscendingPriceOrColor } from '../../../utils/sort.utils';
import { STOCK_STATES } from '../../../utils/constants';

const useStyles = makeStyles(theme => ({
  smallTypography: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.size.small
    }
  },
  titleContainer: {
    marginBottom: '1.075rem',
    fontSize: 'large',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.25),
    }
  },
  descriptionContainer: {
    fontSize: 'large',
    marginBottom: '1.075rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    }

  }
}));

const TITTLE_NOT_LOGGUED = '¿Querés solicitar una reserva?';

const StockList = ({
  stockList,
  onReservationClick,
  isCatalogCarmuv = false,
  currency_symbol,
  setSelectedStock,
  selectedStock,
  isLoggedIn
}) => {
  const [priceSortedStockList, setPriceSortedStockList] = useState([]);
  const TEXT_ONE_COLOR = 'Confirmá tu solicitud y nos comunicaremos con vos para continuar el proceso.';
  const TEXT_MULTIPLE_COLORS = 'Seleccioná el color que quieras y nos comunicaremos con vos para continuar el proceso.';
  const TEXT_0KM = priceSortedStockList.length === 1 ? TEXT_ONE_COLOR : TEXT_MULTIPLE_COLORS;

  const classes = useStyles();
  useEffect(() => {
    const stockActives = stockList.filter(s=> s.estado !== STOCK_STATES.RESERVED).sort(sortByAscendingPriceOrColor);
    const stockReserved = stockList.filter(s=> s.estado === STOCK_STATES.RESERVED);
    const stocks = [...stockActives, ...stockReserved];
    setPriceSortedStockList(stocks);
  }, []);

  useEffect(() => {
    setSelectedStock(priceSortedStockList[0]?.id);
  }, [priceSortedStockList]);

  return (
    <Box marginTop="1.5rem" display="flex" flexDirection="column" alignItems="center">
      {stockList.length > 0 &&
        <>
          {isLoggedIn ?
            <>
              <Box marginBottom="0.5rem" textAlign="left" width="100%">
                <Text bold>{TITTLE_NOT_LOGGUED}</Text>
              </Box>
              <Box marginBottom="1.5rem" textAlign="left" width="100%">
                <Text> {TEXT_0KM} </Text>
              </Box></>
            :
            <>
              <Box marginRight="auto" fontSize="large" className={classes.titleContainer}>
                <Text className={classes.smallTypography} bold>{TITTLE_NOT_LOGGUED}</Text>
              </Box>
              <Box className={classes.descriptionContainer}>
                <Text className={classes.smallTypography}>{TEXT_0KM}</Text>
              </Box>
            </>
          }
          <Box width="100%">
            {priceSortedStockList
              .map((item, index) => {
                return <StockDetail
                  key={item.id}
                  index={index}
                  selected={selectedStock === item.id}
                  price={item.price}
                  color={item.color}
                  observation={item.observation}
                  discount={item.discount}
                  stockId={item.id}
                  onSelect={setSelectedStock}
                  onReservationClick={onReservationClick}
                  isCatalogCarmuv={isCatalogCarmuv}
                  currency_symbol={currency_symbol}
                  isLoggedIn={isLoggedIn}
                  isReserved={item['estados'].every(s=> s === STOCK_STATES.RESERVED)}
                />;
              })}
          </Box>
        </>}
    </Box>
  );
};

export default StockList;
