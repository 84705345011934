import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import StockList from '../stockCard/StockList.component';

const Licenciado = ({ onReservationClick, stockList, currency_symbol, selectedStock, setSelectedStock, isLoggedIn }) => {

  return(
    <Box>
      <StockList
        stockList={stockList}
        selectedStock={selectedStock}
        setSelectedStock={setSelectedStock}
        isCatalogCarmuv
        onReservationClick={onReservationClick}
        currency_symbol={currency_symbol}
        isLoggedIn={isLoggedIn}/>
    </Box>
  );
};

Licenciado.prototype = {
  onReservationClick: PropTypes.func
};

Licenciado.defaultProps = {
  onReservationClick: () => {}
};


export default Licenciado;
