import React, { useState } from 'react';
import { makeStyles, Box, useMediaQuery, DialogContent, DialogActions } from '@material-ui/core';
import Text from '../common/text.component';
import SuggestionForm from './SuggestionForm.component';
import ModalFormButton from '../common/ModalFormButton.component';
import { InformationCard } from '../CarDetail';
import { theme as globalTheme } from '../testUtils';
import { LoadingMessage } from '../common/LoadingModal.component';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '../common/Dialog.component';
import Button from '../common/button.component';
import FlashMessage from '../common/FlashMessage.component';


const MODAL_TITLE = 'Solicitud de modificación de ficha técnica';
const LOADING_LEYEND = 'Estamos enviando su sugerencia';
const COMFIRM_BTN_TEXT = 'Enviar sugerencia';
const CONTINUE_BTN_TEXT = 'Continuar navegando';
const SUBMIT_ERROR_MESSAGE = '¡ Lo sentimos ! No se pudo enviar la sugerencia, intentá nuevamente.';
const SUCCESS_TITLE = 'Sugerencia enviada con éxito.';
const SUCCES_MESSAGE = 'Nuestro equipo evaluará tu sugerencia y corregirá los datos de ser necesario.';

const FORM_INITIAL_FIELDS = {
  suggestion: '',
};

const useStyles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.25rem',
      minHeight: '28rem',
      width: '30rem'
    }
  },
  title: {
    display:'block',
    textAlign: 'center',
    fontSize:'1rem',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '90%',
    textTransform: 'none',
    justifySelf: 'flex-end',
  },
  buttonContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  header: {
    width: '90%',
    margin: 'auto'
  },
  informationCard: {
    maxWidth: '60%',
    paddingBottom: theme.spacing(3),
  },
  successMessage: {
    display:'block',
    marginBottom: theme.spacing(4)
  },
  closeButton: {
    fontSize: '1.25rem',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const SuggestDialog = (
  {
    open,
    car,
    submitError,
    onBack,
    loading,
    success,
    onConfirm,
    onContinue,
    logo,
  }) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(globalTheme.breakpoints.down('sm'));
  const [fields, setFields] = useState(FORM_INITIAL_FIELDS);
  const [formSubmited, setFormSubmited] = useState(false);
  const [formHasError, setFormHasError] = useState(false);

  const handleOnFieldChanges = (newFields) => {
    setFields(newFields);
    submitError = false;
  };

  const isFormIncomplete = () => fields === null || !fields.suggestion;

  const handleOnSubmit = () => {
    setFormSubmited(true);
    !formHasError && onConfirm(fields);
  };

  const handleOnError = (hasError) => {
    setFormHasError(hasError);
  };

  const handleClose = () => {
    clearDialog();
    onBack();
  };

  const clearDialog = () => {
    setFields( FORM_INITIAL_FIELDS );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}>
      <DialogContent className={classes.dialog} dividers>
        { !fullScreen ? (
          <CloseIcon className={classes.closeButton} onClick={handleClose}/>
        ) : null }
        { loading ?
          <OnLoadingContent
            loadingLeyend={LOADING_LEYEND} />
          : success ?
            <FlashMessage
              success
              onContinue={onContinue}
              succesTitle={SUCCESS_TITLE}
              successMessage={SUCCES_MESSAGE}
              continueButtonText={CONTINUE_BTN_TEXT}
              fullScreen={fullScreen}
            />
            :
            <Box>
              <Text bold className={classes.title}>{MODAL_TITLE}</Text>
              <Box  className={classes.header}>
                <Box className={classes.informationCard} >
                  <InformationCard
                    brandName={car.brand.name}
                    versionName={car.version.name}
                    modelName={car.model.name}
                    factoryPrice={car.factoryPrice}
                    logo={logo}
                    isLoggedIn/>
                </Box>
              </Box>
              <SuggestionForm
                submited={formSubmited}
                fields={fields}
                onSubmit={handleOnSubmit}
                onError={handleOnError}
                onFieldChanges={handleOnFieldChanges} />
              { !fullScreen &&
              <Box className={classes.buttonContainer}>
                <ModalFormButton
                  primary
                  data-test-id="confirmar-sugerencia-btn"
                  submitError={submitError}
                  submitErrorMessage={SUBMIT_ERROR_MESSAGE}
                  text={COMFIRM_BTN_TEXT}
                  onConfirm={handleOnSubmit}
                  disable={isFormIncomplete()}
                  className={classes.button} />
              </Box>
              }
            </Box>
        }
      </DialogContent>
      { fullScreen && !loading &&
      <DialogActions>
        {!success ?
          (
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                primary
                data-test-id="confirmar-sugerencia-dialogBtn"
                disabled={isFormIncomplete()}
                onClick={handleOnSubmit}>
                {COMFIRM_BTN_TEXT}
              </Button>
            </Box>
          ) :
          (
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                primaryInverted
                data-test-id="continuar-sugerencia-dialogBtn"
                onClick={() => onContinue()} >
                {CONTINUE_BTN_TEXT}
              </Button>
            </Box>
          )
        }
      </DialogActions>}
    </Dialog>
  );
};

const OnLoadingContent = ({ loadingMessage,  loadingLeyend }) => {
  return (
    <Box
      display='flex'
      justifyContent='center' alignItems='center'>
      <LoadingMessage
        message={loadingMessage}
        leyend={loadingLeyend} />
    </Box>
  );
};

export default SuggestDialog;
