import React from 'react';
import SuggestDialog from '../../components/suggestion/SuggestDialog.component';
import { useMutation } from '@apollo/react-hooks';
import { SEND_SUGGESTION } from '../../mutations';

const SuggestionDialogContainer = ({
  open,
  car,
  logo,
  applicant,
  onBack,
}) => {

  const [sendSuggestion, { loading, data, error }] = useMutation(SEND_SUGGESTION);

  const handleOnConfirm = (fields) => {
    const suggestionInput = {
      applicantEmail: applicant.profile.email,
      applicantFirstName: applicant.profile.firstname,
      applicantLastName: applicant.profile.lastname,
      companyId: car.company.id,
      carLotId: car.carLot.id,
      brand: car.brand.name,
      model: car.model.name,
      version: car.version.name,
      description: fields.suggestion
    };

    sendSuggestion({ variables: { suggestion: suggestionInput } });
  };

  const success = data?.createDataSheetChange.id !== undefined || false;

  return (
    <SuggestDialog
      open={open}
      car={car}
      logo={logo}
      submitError={error}
      onBack={onBack}
      loading={loading}
      success={success}
      onConfirm={handleOnConfirm}
      onContinue={onBack}
    />
  );
};

export default SuggestionDialogContainer;
