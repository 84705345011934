import React from 'react';
import ReservaDialog from '../../components/reserva/ReservaDialog.component';
import { useMutation } from '@apollo/react-hooks';
import { ADD_RESERVE } from '../../mutations';
import { GET_CAR } from '../../queries';

const TYPE_USER_REVENTA = 'Reventa';
const TYPE_USER_PARTICULAR = 'Particular';

const ReservaDialogContainer = ({
  open,
  car,
  logo,
  onBack,
  price,
  stockId,
  color,
  selectedStock,
  applicant,
  isLoggedIn,
}) => {

  const [addReserve, { loading, data, error }] = useMutation(ADD_RESERVE, {
    refetchQueries: [{
      query: GET_CAR,
      variables: { id: car.id },
    }]
  });

  const handleOnConfirm = (fields) => {

    const reserveInput = {
      stockId: car.is0km ? stockId : car.stock[0].id,
      brand: car.brand.name,
      model: car.model.name,
      applicantEmail: isLoggedIn ? applicant?.profile?.email : fields.email,
      applicantFirstName: isLoggedIn ? applicant?.profile?.firstname : fields.name,
      applicantLastName: isLoggedIn ? applicant?.profile?.lastname : fields.lastname,
      versionId: car.version.id,
      carLotId: car.carLot.id,
      vehicleColor: car.is0km ? color.name : car.stock[0].color.name,
      hexColor: car.is0km ? color.color || '' : car.stock[0].color.color || '',
      price: car.is0km ? price : car.resalePrice,
      year: car.year,
      clientFirstName: fields.name,
      clientLastName: fields.lastname,
      numberPhone: fields.telephone,
      condition: Number(car.is0km),
      branchOffice: car.branchOfficeInfo.name,
      patent: car.is0km ? '' : car.patent,
      currencySimbol: car.currency_symbol,
      applicantType: isLoggedIn ? TYPE_USER_REVENTA : TYPE_USER_PARTICULAR
    };

    addReserve({ variables: { reserve: reserveInput } });
  };

  const success = data?.addReserve.id !== undefined || false;
  return (
    <ReservaDialog
      open={open}
      car={car}
      logo={logo}
      submitError={error}
      onBack={onBack}
      loading={loading}
      success={success}
      onConfirm={handleOnConfirm}
      onContinue={onBack}
      observation={selectedStock?.observation}
      price={price}
      colorHex={color?.color}
      colorName={color?.name}
      isLoggedIn={isLoggedIn}
    />
  );
};

export default ReservaDialogContainer;
